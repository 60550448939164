// import { useState, useEffect } from "react";
// import React from "react";
// import { motion } from "framer-motion";

// // const CountdownTimer = ({ targetDate }) => {
// //   const calculateTimeLeft = () => {
// //     const difference = +new Date(targetDate) - +new Date();
// //     let timeLeft = {};
// //     if (difference > 0) {
// //       timeLeft = {
// //         d: Math.floor(difference / (1000 * 60 * 60 * 24)),
// //         h: Math.floor((difference / (1000 * 60 * 60)) % 24),
// //         m: Math.floor(((difference / 1000) * 60) % 60),
// //         s: Math.floor((difference / 1000) % 60),
// //       };
// //     }
// //     return timeLeft;
// //   };

// //   const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

// //   useEffect(() => {
// //     const timer = setTimeout(() => {
// //       setTimeLeft(calculateTimeLeft());
// //     }, 1000);
// //     return () => clearTimeout(timer);
// //   });

// //   return (
// //     <span className="inline-flex items-center space-x-1">
// //       {Object.entries(timeLeft).map(([key, value]) => (
// //         <span key={key} className="inline-flex flex-col items-center bg-white bg-opacity-20 rounded px-1.5 py-0.5">
// //           <span className="text-2xl font-bold tabular-nums leading-none">{value.toString().padStart(2, "0")}</span>
// //           <span className="text-[10px] uppercase font-semibold leading-tight">{key}</span>
// //         </span>
// //       ))}
// //     </span>
// //   );
// // };

// // const TopBanner = () => {
// //   const targetDate = new Date("September 23, 2024 23:59:59");

// //   return (
// //     <div className="bg-gradient-to-r from-green-600 to-green-700 text-white py-2 px-4 flex items-center justify-center overflow-hidden">
// //       <div className="flex flex-wrap items-center justify-center gap-4 md:gap-6">
// //         <span className="text-2xl font-bold whitespace-nowrap flex items-center">
// //           <span className="text-4xl mr-1">🚀</span>
// //           <span className="bg-white text-green-700 px-2 py-0.5 rounded-full">50% OFF</span>
// //           <span className="ml-1">All Plans</span>
// //         </span>
// //         <div className="flex items-center">
// //           <span className="text-base font-semibold mr-2">Use code:</span>
// //           <span className="bg-white text-green-700 px-3 py-1 rounded-full font-bold text-lg tracking-wider hover:bg-opacity-90 transition-colors">
// //             I3OTQ1OQ
// //           </span>
// //         </div>
// //         <div className="flex items-center">
// //           <span className="text-base font-semibold mr-2">Ends in:</span>
// //           <CountdownTimer targetDate={targetDate} />
// //         </div>
// //       </div>
// //     </div>
// //   );
// // };

// // You can switch between 'blue' and 'green' themes
// // const theme = "blue"; // or 'green'
// const theme = "green"; // or 'green'

// const TopBanner = () => {
//   const bgColor = theme === "blue" ? "bg-blue-600" : "bg-green-700";
//   const textColor = "text-white";
//   const linkColor = "text-white hover:text-white";

//   return (
//     <motion.div className={`${bgColor} py-3 px-4`} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }}>
//       <div className="container mx-auto flex items-center justify-center">
//         <motion.p
//           className={`text-sm md:text-base font-normal ${textColor} tracking-wide`}
//           initial={{ y: 10, opacity: 0 }}
//           animate={{ y: 0, opacity: 1 }}
//           transition={{ delay: 0.2, duration: 0.5 }}
//         >
//           {/* Introducing NeedFinderAI. Your AI Cofounder! */}
//           {/* Introducing NeedFinderAI. Your 24/7 AI Lead Generation Engine! */}
//           {/* 🔥 Real-Time Market Intelligence is Coming to FeedbackbyAI this week! Stay tuned. */}
//           {/* 🔥 Spotlight.E is coming soon to FeedbackByAI! Stay tuned. */}
//           {/* 🚀 Launch Your Startup to 10,000+ Businesses with SpotLight•E. Coming this week. */}
//           🚀 Start Building Your SpotLight•E profile.✨ Showcase Your Startup to 10,000+ Businesses. Launching This Week!
//           {/* 🔥 DoorOpeners has launched to FeedbackByAI! Try it now. */}
//           {/* 🔥 Coming Soon: Real-Time Brand Monitoring – Stay Ahead of the Buzz! 🔥 */}
//           {/* 🤝 Unlock Your Network's Potential with DoorOpeners ➡️ */}
//           {/* <motion.a
//             href="https://needfinderai.com"
//             target="_blank"
//             rel="noopener noreferrer"
//             className={`ml-2 ${linkColor} font-bold hover:underline  underline transition-all duration-300`}
//             whileHover={{ x: 3 }}
//           >
//             Learn More
//           </motion.a> */}
//         </motion.p>
//       </div>
//     </motion.div>
//   );
// };

// export default TopBanner;

import React from "react";
import { Sparkles, ExternalLink, Video } from "lucide-react";

const TopBanner = () => {
  return (
    <div className="w-full bg-gradient-to-r from-emerald-950 via-emerald-900 to-teal-900 border-b border-emerald-400/10">
      <div className="max-w-7xl mx-auto">
        <a
          href="/spotlighte/create/"
          className="px-4 py-3 flex items-center justify-center gap-3 text-sm text-emerald-100 hover:text-white transition-colors group"
        >
          <Sparkles className="h-3.5 w-3.5 text-emerald-400" />
          <span>
            {/* <span className="font-semibold">Start Building Your SpotLight•E Profile</span> */}
            {/* <span className="font-semibold">PitchConnect - Pitch your idea to 10,000+ business on FeedbackByAI</span> */}
            <span className="font-semibold">
              {/* 🎉 2025 NEW YEAR SPECIAL 🎉. 50% OFF ALL PLANS Code: 2025NEWYEAR Valid: 7 days only. Turn your idea into reality at our best
              price ever. */}
              🎯 50% OFF TODAY: Build your startup for half the price with code: 2025NEWYEAR
              {/* Tired of endless pitching and 100x verifications? There's a better way to get your startup funded. Coming soon. */}
              {/* Stop wasting time on endless pitching. Get your startup funded faster. Coming soon. */}
              {/* 50% Off on all our plans only for this
              week. Use Code "2025NEWYEAR". */}
              {/* New: PitchConnect - Let 10k business on FeedbackByAI instantly book meetings based on your startup pitch. AI Verified requests */}
              {/* Pitch Your Startup in 60 Seconds .Get Meeting Requests from 10k+ FeedbackByAI Members • AI Screens All Requests */}
              {/* PITCHCONNECT - 60-Second Video Pitch Board for Entrepreneurs. */}
              {/* Post 60-second video pitch of your startup, let business book meetings with you. */}
              {/* New: PitchConnect - Post 60-second video pitch of your startup, let business book meetings with you. */}
            </span>
            <br />
            {/* <span className="mx-2 text-emerald-300">•</span> */}
            {/* <span>Find customers, partners, suppliers, distributors </span> */}
            {/* <span>Free For First 100 Pitch</span> */}
            {/* <span>Signup to be</span> */}
            {/* <span className="mx-2 text-emerald-300">•</span> */}

            <span className="text-emerald-400 font-medium">
              {/* Pitch your startup in 60 seconds and get meeting requests From 10k+ FeedbackByAI Members. | AI Screens All Requests. */}
              {/* | 30 days */}
              {/* Free Visibility for First 20 Pitches{" "} */}
            </span>

            {/* <span className="text-emerald-400 font-medium">Create Your Pitch!</span> */}
          </span>
          {/* <ExternalLink className="h-3.5 w-3.5 opacity-70 group-hover:opacity-100 transition-opacity" /> */}
        </a>
      </div>
    </div>
  );
};

export default TopBanner;

// import React, { useState } from "react";
// import { Sparkles, ArrowRight } from "lucide-react";

// const TopBanner = () => {
//   const [hasClicked, setHasClicked] = useState(false);

//   const handleClick = () => {
//     setHasClicked(true);
//     window.location.href = "mailto:business@feedbackbyai.com?subject=🚀 PROOV Early Access&body=Hi, I'd like early access to PROOV.";
//   };

//   return (
//     <div className="w-full bg-gradient-to-r from-emerald-600 to-emerald-500 relative overflow-hidden">
//       {/* Animated shine effect */}
//       <div className="absolute inset-0 bg-gradient-to-r from-transparent via-white/10 to-transparent animate-shine" />

//       <div className="max-w-7xl mx-auto relative">
//         <button
//           onClick={handleClick}
//           disabled={hasClicked}
//           className="w-full px-4 py-3 flex items-center justify-center gap-3 text-base text-white transition-all duration-300 group hover:bg-emerald-500"
//         >
//           {!hasClicked ? (
//             <>
//               <Sparkles className="h-5 w-5 text-emerald-100" />
//               <span className="font-medium">👀 Building a better way to get funded. No more endless pitch decks.</span>
//               <span className="flex items-center gap-1 text-emerald-50 font-medium group-hover:translate-x-1 transition-transform duration-300 ml-1">
//                 Join waitlist
//                 <ArrowRight className="h-5 w-5" />
//               </span>
//             </>
//           ) : (
//             <span className="text-emerald-50 font-medium flex items-center gap-2">
//               <Sparkles className="h-5 w-5" />
//               Check your email to confirm
//             </span>
//           )}
//         </button>
//       </div>

//       {/* CSS for shine animation */}
//       <style jsx>{`
//         @keyframes shine {
//           from {
//             transform: translateX(-100%);
//           }
//           to {
//             transform: translateX(200%);
//           }
//         }
//         .animate-shine {
//           animation: shine 4s infinite linear;
//         }
//       `}</style>
//     </div>
//   );
// };

// export default TopBanner;

// import React, { useState } from "react";
// import { ArrowRight, X, Check, Loader, Star, BarChart } from "lucide-react";

// const ProovLogo = () => (
//   <div className="flex items-center gap-2">
//     <div className="relative">
//       <div className="bg-gradient-to-r from-emerald-500 to-emerald-600 w-8 h-8 rounded-lg flex items-center justify-center transform rotate-3 shadow-lg">
//         <span className="text-lg font-bold text-white transform -rotate-3">P</span>
//       </div>
//     </div>
//     <span className="font-bold text-white">PROOV</span>
//   </div>
// );

// const WaitlistForm = ({ onClose }) => {
//   const [isSubmitting, setIsSubmitting] = useState(false);
//   const [showSuccess, setShowSuccess] = useState(false);

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setIsSubmitting(true);

//     try {
//       const formData = new FormData(e.target);
//       await fetch("https://formsubmit.co/business@feedbackbyai.com", {
//         method: "POST",
//         body: formData,
//       });
//       setShowSuccess(true);
//       setTimeout(() => onClose(true), 2000);
//     } catch (error) {
//       setIsSubmitting(false);
//     }
//   };

//   if (showSuccess) {
//     return (
//       <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
//         <div className="bg-white rounded-xl p-12 w-full max-w-md relative flex flex-col items-center animate-success">
//           <div className="w-16 h-16 bg-emerald-100 rounded-full flex items-center justify-center mb-6">
//             <Check className="w-8 h-8 text-emerald-600" />
//           </div>
//           <h3 className="text-2xl font-bold text-gray-900 mb-2">You're In!</h3>
//           <p className="text-gray-500 text-center">Reserved: Early access on Jan 24th</p>
//         </div>
//       </div>
//     );
//   }

//   return (
//     <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
//       <div className="bg-white rounded-xl p-8 w-full max-w-md relative">
//         <button onClick={() => onClose(false)} className="absolute top-4 right-4 text-gray-400 hover:text-gray-600">
//           <X className="w-5 h-5" />
//         </button>

//         {/* Form Header */}
//         <div className="text-center mb-8">
//           <div className="inline-flex items-center gap-2 text-sm bg-emerald-50 text-emerald-600 px-3 py-1 rounded-full mb-4">
//             <Star className="w-4 h-4" />
//             <span>From the FeedbackbyAI team (10k+ startups)</span>
//           </div>
//           {/* <h3 className="text-2xl font-bold text-gray-900 mb-3">Replace Pitch Decks</h3> */}
//           <h3 className="text-2xl font-bold text-gray-900 mb-3">Stop Pitching. Start Proving</h3>

//           <p className="text-gray-600">The effortless way to fundraise with verified progress.</p>
//         </div>

//         {/* Quick Benefits */}
//         {/* <div className="grid grid-cols-3 gap-3 mb-8">
//           <div className="bg-gray-50 rounded-lg p-3">
//             <div className="text-lg font-bold text-emerald-600">3+ months</div>
//             <div className="text-sm text-gray-600">Forget endless meetings and verifications.</div>
//           </div>
//           <div className="bg-gray-50 rounded-lg p-3">
//             <div className="text-lg font-bold text-emerald-600">2 min</div>
//             <div className="text-sm text-gray-600">Connect metrics. Share progress.</div>
//           </div>
//           <div className="bg-gray-50 rounded-lg p-3">
//             <div className="text-lg font-bold text-emerald-600">50+</div>
//             <div className="text-sm text-gray-600">One update, multiple investors. Meetings come to you.</div>
//           </div>
//         </div> */}

//         <form onSubmit={handleSubmit} className="space-y-4">
//           <input type="hidden" name="_subject" value="PROOV Waitlist Signup!" />
//           <input type="hidden" name="_captcha" value="false" />
//           <input type="hidden" name="_next" value={window.location.href} />

//           <div className="space-y-4">
//             <div>
//               <input
//                 type="email"
//                 name="email"
//                 required
//                 disabled={isSubmitting}
//                 className="w-full px-4 py-3 bg-gray-50 border border-gray-200 rounded-lg focus:ring-2 focus:ring-emerald-500 focus:border-emerald-500 disabled:opacity-50"
//                 placeholder="Work email"
//               />
//             </div>

//             {/* <div>
//               <input
//                 type="text"
//                 name="company"
//                 required
//                 disabled={isSubmitting}
//                 className="w-full px-4 py-3 bg-gray-50 border border-gray-200 rounded-lg focus:ring-2 focus:ring-emerald-500 focus:border-emerald-500 disabled:opacity-50"
//                 placeholder="Company name"
//               />
//             </div> */}

//             <div>
//               <input
//                 type="url"
//                 name="website"
//                 disabled={isSubmitting}
//                 className="w-full px-4 py-3 bg-gray-50 border border-gray-200 rounded-lg focus:ring-2 focus:ring-emerald-500 focus:border-emerald-500 disabled:opacity-50"
//                 placeholder="Company website"
//               />
//             </div>

//             <div>
//               <select
//                 name="role"
//                 required
//                 disabled={isSubmitting}
//                 className="w-full px-4 py-3 bg-gray-50 border border-gray-200 rounded-lg focus:ring-2 focus:ring-emerald-500 focus:border-emerald-500 disabled:opacity-50"
//               >
//                 <option value="">Select your role</option>
//                 <option value="founder">Founder/Co-founder</option>
//                 <option value="investor">Investor</option>
//               </select>
//             </div>
//           </div>

//           <button
//             type="submit"
//             disabled={isSubmitting}
//             className="w-full bg-emerald-600 text-white py-3 rounded-lg font-medium hover:bg-emerald-700 transition-all flex items-center justify-center gap-2 mt-6 disabled:opacity-50 disabled:cursor-not-allowed relative overflow-hidden"
//           >
//             {isSubmitting ? (
//               <>
//                 <Loader className="w-5 h-5 animate-spin" />
//                 <span>Securing your spot...</span>
//               </>
//             ) : (
//               <>
//                 Get Early Access
//                 <ArrowRight className="w-4 h-4" />
//               </>
//             )}
//           </button>

//           <div className="text-center">
//             <div className="inline-flex items-center gap-1 text-xs text-gray-500 px-2 py-1 bg-gray-50 rounded-full">
//               <BarChart className="w-3 h-3" />
//               <span>30 founder spots left</span>
//             </div>
//           </div>
//         </form>
//       </div>
//     </div>
//   );
// };

// const TopBanner = () => {
//   const [showForm, setShowForm] = useState(false);
//   const [hasJoined, setHasJoined] = useState(false);

//   return (
//     <>
//       <div className="w-full bg-gradient-to-r from-emerald-600 to-emerald-500 relative">
//         <div className="absolute inset-y-0 left-0 w-48 bg-gradient-to-r from-white/10 to-transparent"></div>
//         <div className="absolute inset-y-0 right-0 w-48 bg-gradient-to-l from-white/10 to-transparent"></div>

//         <div className="max-w-7xl mx-auto relative">
//           <button
//             onClick={() => setShowForm(true)}
//             className="w-full px-4 py-3 flex items-center justify-center text-white transition-all group"
//           >
//             {!hasJoined ? (
//               <div className="flex items-center gap-4">
//                 <ProovLogo />
//                 {/* <span className="text-sm font-medium">Founders: Replace pitch decks with 2-min weekly updates</span> */}
//                 <span className="text-sm font-medium">Effortlessly raise funding for your startup.</span>

//                 <span className="flex items-center gap-2 px-3 py-1 rounded-full bg-white/10 text-sm">
//                   {/* 30 spots left */}
//                   Get Early Access.
//                   <ArrowRight className="w-4 h-4" />
//                 </span>
//               </div>
//             ) : (
//               <div className="flex items-center gap-2 text-sm">
//                 <Check className="w-4 h-4" />
//                 <span>Reserved: Early access on Jan 24th</span>
//               </div>
//             )}
//           </button>
//         </div>
//       </div>

//       {showForm && (
//         <WaitlistForm
//           onClose={(submitted) => {
//             setShowForm(false);
//             if (submitted) setHasJoined(true);
//           }}
//         />
//       )}

//       <style jsx>{`
//         .animate-success {
//           animation: success 0.5s ease-out;
//         }
//         @keyframes success {
//           from {
//             transform: scale(0.95);
//             opacity: 0;
//           }
//           to {
//             transform: scale(1);
//             opacity: 1;
//           }
//         }
//       `}</style>
//     </>
//   );
// };

// export default TopBanner;
