import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import api from "./axiosConfig";
import DashboardHeader from "./DashboardHeader";
import ReportFooter from "./ReportFooter";
import Modal from "react-modal";
import {
  ChevronDown,
  Sparkles,
  Radio,
  Eye,
  MessageSquare,
  Clock,
  Target,
  Gift,
  Star,
  ChevronRight,
  X,
  ExternalLink,
  Megaphone,
  Activity,
  Users,
  Calendar,
  Building,
  TrendingUp,
  Mail,
  CheckCircle,
  Search,
  ArrowRight,
  Zap,
  Shield,
} from "lucide-react";
import TopBanner from "./TopBanner";

const CustomModal = ({ isOpen, onRequestClose, content }) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(content);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="fixed inset-0 z-50 flex items-center justify-center"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-40"
    >
      <motion.div
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.95 }}
        transition={{ duration: 0.3 }}
        className="bg-white rounded-2xl shadow-xl max-w-lg w-full mx-4 p-6"
      >
        <h3 className="text-xl font-semibold text-gray-900 mb-4">Full Input</h3>
        <div className="max-h-96 overflow-y-auto p-4 bg-gray-50 rounded-lg mb-4">
          <p className="text-sm text-gray-700 whitespace-pre-wrap">{content}</p>
        </div>
        <div className="flex justify-end space-x-3">
          <button
            onClick={handleCopy}
            className={`px-4 py-2 rounded-lg text-white font-medium transition-colors ${
              isCopied ? "bg-green-600" : "bg-blue-600 hover:bg-blue-700"
            }`}
          >
            {isCopied ? "Copied!" : "Copy Full Text"}
          </button>
          <button
            onClick={onRequestClose}
            className="px-4 py-2 bg-gray-200 rounded-lg text-gray-700 font-medium hover:bg-gray-300 transition-colors"
          >
            Close
          </button>
        </div>
      </motion.div>
    </Modal>
  );
};

const BusinessMatchModal = ({ isOpen, onClose }) => {
  const [view, setView] = useState("discover");
  const [category, setCategory] = useState("all");

  const matches = [
    {
      id: 1,
      name: "Supplier Hub Asia",
      type: "Distribution",
      verified: true,
      location: "Singapore",
      deals: "50+",
      offering: "Direct Factory Access",
      benefits: ["30-60 days credit", "Bulk discounts", "Free shipping"],
    },
    {
      id: 2,
      name: "Euro Marketing Co",
      type: "Marketing",
      verified: true,
      location: "Germany",
      deals: "120+",
      offering: "Market Entry Support",
      benefits: ["Local marketing", "Store placement", "PR support"],
    },
  ];

  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 bg-black/40 backdrop-blur-md flex items-center justify-center p-4 z-50"
      onClick={(e) => e.target === e.currentTarget && onClose()}
    >
      <div className="bg-white rounded-3xl shadow-lg max-w-3xl w-full overflow-hidden">
        {/* Modal Header */}
        <div className="border-b p-4 flex justify-between items-center bg-gray-50">
          <div className="text-lg font-semibold text-gray-800">Spotlight•E </div>
          <button onClick={onClose} className="hover:bg-gray-100 p-2 rounded-full transition-colors">
            <X className="w-5 h-5 text-gray-600" />
          </button>
        </div>

        {/* Modal Content */}
        <div className="bg-slate-900 p-6 h-[75vh] overflow-y-auto">
          {/* Quick Actions Bar */}
          <div className="bg-white/5 backdrop-blur border border-white/10 rounded-lg p-4 flex flex-wrap justify-between items-center gap-4">
            <div className="flex items-center gap-4">
              {["discover", "matches", "deals"].map((tab) => (
                <button
                  key={tab}
                  onClick={() => setView(tab)}
                  className={`px-4 py-2 rounded-lg ${
                    view === tab ? "bg-blue-500 text-white" : "text-white/60 hover:bg-blue-500 hover:text-white"
                  }`}
                >
                  {tab.charAt(0).toUpperCase() + tab.slice(1)}
                </button>
              ))}
            </div>
            <div className="flex items-center gap-2">
              <span className="text-white/60">Your Profile:</span>
              <span className="text-white">85% Complete</span>
            </div>
          </div>

          {/* Main Content */}
          <div className="mt-6 space-y-6">
            <div className="flex justify-between items-center flex-wrap gap-4">
              <div>
                <h1 className="text-2xl font-bold text-white">Find Business Opportunities</h1>
                <p className="text-white/60">140+ countries, 10,000+ verified businesses</p>
              </div>
              <div className="flex items-center gap-2">
                <Search className="w-5 h-5 text-white/40" />
                <input
                  type="text"
                  placeholder="Search opportunities..."
                  className="bg-white/5 border border-white/10 rounded-lg px-4 py-2 text-white placeholder-white/40 focus:outline-none"
                />
              </div>
            </div>

            {/* Category Pills */}
            <div className="flex flex-wrap gap-2">
              {["All", "Retail", "Distribution", "Marketing", "Manufacturing"].map((cat) => (
                <button
                  key={cat}
                  onClick={() => setCategory(cat.toLowerCase())}
                  className={`px-4 py-2 rounded-full text-sm ${
                    category === cat.toLowerCase() ? "bg-blue-500 text-white" : "bg-white/5 text-white/60 hover:bg-white/10"
                  }`}
                >
                  {cat}
                </button>
              ))}
            </div>

            {/* Matches Grid */}
            <div className="space-y-4">
              {matches.map((match) => (
                <div key={match.id} className="bg-white/5 rounded-lg p-6 hover:bg-white/10 transition-all group">
                  <div className="flex justify-between items-start mb-4">
                    <div>
                      <div className="flex items-center gap-2">
                        <h3 className="text-lg font-semibold text-white">{match.name}</h3>
                        {match.verified && <CheckCircle className="w-4 h-4 text-blue-400" />}
                      </div>
                      <p className="text-white/60 mt-1">
                        {match.type} • {match.location}
                      </p>
                    </div>
                    <span className="bg-blue-500/20 text-blue-300 px-3 py-1 rounded-full text-sm">{match.deals} Deals Done</span>
                  </div>

                  <div className="space-y-4">
                    <div>
                      <div className="text-white/60 mb-2">Offering:</div>
                      <div className="text-white font-medium">{match.offering}</div>
                    </div>

                    <div>
                      <div className="text-white/60 mb-2">Benefits:</div>
                      <div className="grid grid-cols-2 gap-2">
                        {match.benefits.map((benefit, i) => (
                          <div key={i} className="flex items-center gap-1 text-white">
                            <Star className="w-3 h-3 text-blue-400" />
                            <span className="text-sm">{benefit}</span>
                          </div>
                        ))}
                      </div>
                    </div>

                    <button className="w-full bg-blue-500 hover:bg-blue-600 text-white py-2 rounded-lg flex items-center justify-center gap-2 group transition-all">
                      Connect Now
                      <ArrowRight className="w-4 h-4 group-hover:translate-x-1 transition-transform" />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default function ReportDashboard() {
  const location = useLocation();
  const [businessIdea, setBusinessIdea] = useState("");
  const [language, setLanguage] = useState("english");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [latestFullReports, setLatestFullReports] = useState([]);
  const [isFetchingReports, setIsFetchingReports] = useState(false);
  const [message, setMessage] = useState("");
  const [showRechargeLink, setShowRechargeLink] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);
  const [showBoothForm, setShowBoothForm] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [showBusinessMatchModal, setShowBusinessMatchModal] = useState(false);

  useEffect(() => {
    async function fetchReports() {
      setIsFetchingReports(true);
      try {
        const response = await api.get("user-latest-report/");
        if (response.status === 200) {
          setLatestFullReports(response.data);
        }
      } catch (error) {
        console.error("Error fetching reports:", error);
      } finally {
        setIsFetchingReports(false);
      }
    }

    fetchReports();
  }, []);

  async function handleSubmit(e) {
    e.preventDefault();
    setMessage("");
    setShowRechargeLink(false);
    setIsSubmitting(true);

    const timeoutId = setTimeout(() => {
      alert("Submission is taking longer than expected. Please try again.");
      setIsSubmitting(false);
    }, 10000);

    const confirmReport = window.confirm("This will deduct one report credit. Are you sure you want to continue?");
    if (!confirmReport) {
      clearTimeout(timeoutId);
      setIsSubmitting(false);
      return;
    }

    try {
      const response = await api.post("create-report/", {
        business_idea_input: businessIdea,
        language,
      });
      clearTimeout(timeoutId);

      if (response.status === 200 || response.status === 201) {
        window.location.href = `/full-report/${response.data.report_id}`;
      }
    } catch (error) {
      clearTimeout(timeoutId);
      if (error.response) {
        const errorMessage = error.response.data.message;
        setMessage(errorMessage || "An error occurred. Please try again.");
        if (errorMessage.includes("Not enough credits")) {
          setShowRechargeLink(true);
        }
      } else {
        setMessage("An unexpected error occurred.");
      }
      console.error("Failed to submit form:", error.response?.data);
    } finally {
      setIsSubmitting(false);
    }
  }

  const openModal = (report) => {
    setSelectedReport(report);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedReport(null);
  };

  const NetworkPreviewBanner = () => {
    const [showPreview, setShowPreview] = useState(false);

    return (
      <>
        <div className="max-w-4xl mx-auto px-3">
          <div className="bg-gradient-to-br from-emerald-950 via-green-900 to-teal-900 rounded-2xl relative overflow-hidden shadow-2xl border border-emerald-400/10">
            <div className="absolute inset-0 bg-[radial-gradient(circle_at_30%_20%,rgba(16,185,129,0.3),transparent)]" />
            <div className="relative px-6 py-5">
              <div className="space-y-4">
                <div className="inline-flex items-center gap-2 bg-emerald-500/20 px-3 py-1 rounded-full border border-emerald-400/30">
                  <Star className="h-3 w-3 text-emerald-300" />
                  <span className="text-emerald-300 text-xs font-medium">Exclusive Early Access</span>
                </div>

                <div className="space-y-2">
                  <h2 className="text-2xl md:text-3xl text-white font-bold tracking-tight leading-tight">
                    Introducing SpotLight•E
                    <span className="text-emerald-400 ml-2">The Future of Business Networking</span>
                  </h2>
                  <p className="text-white/90 text-sm leading-relaxed max-w-2xl">
                    Be among the first to experience our revolutionary AI-powered business matching platform. Reserve your spot now and get
                    exclusive founder-tier benefits when we launch next week.
                  </p>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-3 gap-3 text-xs">
                  <div className="bg-emerald-500/10 rounded-lg p-3 backdrop-blur-sm border border-emerald-400/10">
                    <div className="flex items-center gap-2">
                      <Zap className="h-4 w-4 text-emerald-400" />
                      <div className="font-semibold text-white">First Access to Premium Features</div>
                    </div>
                  </div>
                  <div className="bg-emerald-500/10 rounded-lg p-3 backdrop-blur-sm border border-emerald-400/10">
                    <div className="flex items-center gap-2">
                      <Shield className="h-4 w-4 text-emerald-400" />
                      <div className="font-semibold text-white">Founder Benefits ($499 Value)</div>
                    </div>
                  </div>
                  <div className="bg-emerald-500/10 rounded-lg p-3 backdrop-blur-sm border border-emerald-400/10">
                    <div className="flex items-center gap-2">
                      <Sparkles className="h-4 w-4 text-emerald-400" />
                      {/* <div className="font-semibold text-white">Launch Week Spotlight </div> */}
                      <div className="font-semibold text-white">Only 100 Startups Will Be Featured at Launch </div>
                    </div>
                  </div>
                </div>

                <div className="flex flex-col sm:flex-row items-center gap-3">
                  <a
                    href="/spotlighte/create/"
                    className="group bg-emerald-500 hover:bg-emerald-400 text-white px-6 py-2.5 rounded-lg text-sm font-semibold flex items-center gap-2 transition-all duration-200 ease-in-out shadow-lg shadow-emerald-500/30 hover:shadow-emerald-500/40 w-full sm:w-auto justify-center"
                  >
                    Reserve Your Spot (in 2 minutes!)
                    <ArrowRight className="w-4 h-4 group-hover:translate-x-0.5 transition-transform" />
                  </a>
                  <div className="flex items-center gap-4 text-xs text-white/70">
                    <div className="flex items-center gap-2">
                      <Shield className="h-3 w-3" />
                      100% Free Early Access
                    </div>
                    <div className="flex items-center gap-2">
                      <Star className="h-3 w-3" />
                      Limited Spots Available
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const RequirementBox = ({ title, items, icon }) => (
    <div className="bg-gray-50 rounded-lg p-4">
      <div className="flex items-center text-blue-600 mb-3">
        {React.cloneElement(icon, { className: "w-4 h-4" })}
        <h3 className="font-medium text-sm ml-2">{title}</h3>
      </div>
      <ul className="space-y-2">
        {items.map((item, i) => (
          <li key={i} className="flex items-start text-gray-700">
            <span className="w-1.5 h-1.5 bg-blue-600 rounded-full mt-1.5 mr-2" />
            <div>
              <div className="text-sm font-medium">{item.title}</div>
              <div className="text-xs text-gray-600">{item.details}</div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );

  const BusinessMatch = () => {
    const [view, setView] = useState("discover");
    const [category, setCategory] = useState("all");
    const [showPreview, setShowPreview] = useState(false);

    const matches = [
      {
        id: 1,
        name: "Supplier Hub Asia",
        type: "Distribution",
        verified: true,
        location: "Singapore",
        deals: "50+",
        responseTime: "< 30 min",
        offering: "Direct Factory Access",
        benefits: ["30-60 days credit", "Bulk discounts", "Free shipping"],
        needs: "Local retailers",
        categories: ["retail", "distribution"],
      },
      {
        id: 2,
        name: "Euro Marketing Co",
        type: "Marketing",
        verified: true,
        location: "Germany",
        deals: "120+",
        responseTime: "< 1 hr",
        offering: "Market Entry Support",
        benefits: ["Local marketing", "Store placement", "PR support"],
        needs: "Consumer brands",
        categories: ["marketing", "retail"],
      },
    ];

    const BusinessCard = ({ match }) => (
      <div className="bg-white/5 rounded-lg p-6 hover:bg-white/10 transition-all group">
        <div className="flex justify-between items-start mb-4">
          <div>
            <div className="flex items-center gap-2">
              <h3 className="text-xl font-semibold text-white">{match.name}</h3>
              {match.verified && <CheckCircle className="w-4 h-4 text-blue-400" />}
            </div>
            <div className="flex items-center gap-3 mt-1">
              <p className="text-white/60">
                {match.type} • {match.location}
              </p>
              <div className="flex items-center gap-1 text-blue-400 text-sm">
                <Clock className="w-3 h-3" />
                {match.responseTime}
              </div>
            </div>
          </div>
          <span className="bg-blue-500/20 text-blue-300 px-3 py-1 rounded-full text-sm">{match.deals} Deals Done</span>
        </div>

        <div className="space-y-4">
          <div>
            <div className="text-white/60 mb-2">Offering:</div>
            <div className="text-white font-medium">{match.offering}</div>
          </div>

          <div>
            <div className="text-white/60 mb-2">Benefits:</div>
            <div className="grid grid-cols-2 gap-2">
              {match.benefits.map((benefit, i) => (
                <div key={i} className="flex items-center gap-1 text-white">
                  <Star className="w-3 h-3 text-blue-400" />
                  <span className="text-sm">{benefit}</span>
                </div>
              ))}
            </div>
          </div>

          <button className="w-full bg-blue-500 hover:bg-blue-600 text-white py-2 rounded-lg flex items-center justify-center gap-2 group">
            Connect Now
            <MessageSquare className="w-4 h-4 group-hover:scale-110 transition-transform" />
          </button>
        </div>
      </div>
    );

    const PreviewModal = () => (
      <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center p-4 z-50">
        <div className="bg-slate-900 rounded-xl max-w-6xl w-full border border-white/10">
          <div className="p-4 border-b border-white/10 flex justify-between items-center">
            <div>
              <h3 className="text-lg font-semibold text-white">Find Business Opportunities</h3>
              <p className="text-sm text-white/60">140+ countries, 10,000+ verified businesses</p>
            </div>
            <button onClick={() => setShowPreview(false)} className="text-white/60 hover:text-white">
              <X className="w-5 h-5" />
            </button>
          </div>

          <div className="max-h-[80vh] overflow-y-auto">
            <div className="p-6 space-y-6">
              <div className="flex gap-2">
                {["All", "Retail", "Distribution", "Marketing", "Manufacturing"].map((cat) => (
                  <button
                    key={cat}
                    className={`px-4 py-2 rounded-full text-sm ${
                      category === cat.toLowerCase() ? "bg-blue-500 text-white" : "bg-white/5 text-white/60 hover:bg-white/10"
                    }`}
                  >
                    {cat}
                  </button>
                ))}
              </div>

              <div className="grid md:grid-cols-2 gap-4">
                {matches.map((match) => (
                  <BusinessCard key={match.id} match={match} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <div className="min-h-screen bg-slate-900">
        <div className="max-w-6xl mx-auto p-4">
          {/* Banner */}
          <div className="bg-gradient-to-br from-blue-950 via-blue-900 to-indigo-900 rounded-xl p-6 mb-8 relative overflow-hidden">
            <div className="absolute inset-0 bg-[radial-gradient(circle_at_10%_0%,rgba(59,130,246,0.2),transparent)]" />
            <div className="relative space-y-4">
              <div className="inline-flex items-center gap-2 bg-blue-500/10 px-3 py-1 rounded-full border border-blue-400/20">
                <Sparkles className="h-4 w-4 text-amber-400" />
                <span className="text-amber-400 text-sm">Launch Week Special</span>
              </div>

              <div className="space-y-2">
                <h1 className="text-2xl font-bold text-white">The Ultimate Networking Hub for Small Businesses</h1>
                <p className="text-white/80">Connect with 10,000+ businesses in 140+ countries</p>
              </div>

              <div className="grid grid-cols-4 gap-2 text-sm">
                <div className="bg-white/10 rounded-lg p-2">
                  <div className="text-white/90">✨ AI Matching</div>
                </div>
                <div className="bg-white/10 rounded-lg p-2">
                  <div className="text-white/90">🤝 Direct Connect</div>
                </div>
                <div className="bg-white/10 rounded-lg p-2">
                  <div className="text-white/90">📊 Analytics</div>
                </div>
                <div className="bg-white/10 rounded-lg p-2">
                  <div className="text-white/90">🔒 Verified</div>
                </div>
              </div>

              <div className="flex items-center gap-3">
                <button className="bg-white text-blue-950 px-4 py-2 rounded-lg text-sm font-semibold">Create Free Profile</button>
                <button onClick={() => setShowPreview(true)} className="text-white/90 hover:text-white text-sm flex items-center gap-2">
                  Preview Platform
                  <ExternalLink className="w-4 h-4" />
                </button>
              </div>
            </div>
          </div>

          {/* Quick Actions Bar */}
          <div className="bg-white/5 backdrop-blur border border-white/10 rounded-lg p-4 flex items-center justify-between">
            <div className="flex items-center gap-4">
              <button
                onClick={() => setView("discover")}
                className={`px-4 py-2 rounded-lg ${view === "discover" ? "bg-blue-500 text-white" : "text-white/60"}`}
              >
                Discover
              </button>
              <button
                onClick={() => setView("matches")}
                className={`px-4 py-2 rounded-lg ${view === "matches" ? "bg-blue-500 text-white" : "text-white/60"}`}
              >
                My Matches
              </button>
              <button
                onClick={() => setView("deals")}
                className={`px-4 py-2 rounded-lg ${view === "deals" ? "bg-blue-500 text-white" : "text-white/60"}`}
              >
                Active Deals
              </button>
            </div>
            <div className="flex items-center gap-2">
              <span className="text-white/60">Your Profile: </span>
              <span className="text-white">85% Complete</span>
            </div>
          </div>

          {/* Main Content */}
          <div className="mt-6">
            <div className="flex justify-between items-center mb-6">
              <div>
                <h1 className="text-2xl font-bold text-white">Find Business Opportunities</h1>
                <p className="text-white/60">140+ countries, 10,000+ verified businesses</p>
              </div>
              <div className="flex items-center gap-2">
                <Search className="w-5 h-5 text-white/40" />
                <input
                  type="text"
                  placeholder="Search opportunities..."
                  className="bg-white/5 border border-white/10 rounded-lg px-4 py-2 text-white placeholder-white/40"
                />
              </div>
            </div>

            <div className="flex gap-2 mb-6">
              {["All", "Retail", "Distribution", "Marketing", "Manufacturing"].map((cat) => (
                <button
                  key={cat}
                  onClick={() => setCategory(cat.toLowerCase())}
                  className={`px-4 py-2 rounded-full text-sm ${
                    category === cat.toLowerCase() ? "bg-blue-500 text-white" : "bg-white/5 text-white/60 hover:bg-white/10"
                  }`}
                >
                  {cat}
                </button>
              ))}
            </div>

            <div className="grid md:grid-cols-2 gap-4">
              {matches.map((match) => (
                <BusinessCard key={match.id} match={match} />
              ))}
            </div>
          </div>

          {showPreview && <PreviewModal />}
        </div>
      </div>
    );
  };

  return (
    <div className="font-sans bg-gradient-to-b from-green-50 to-white min-h-screen">
      <TopBanner />
      <DashboardHeader />
      {/* <NetworkPreviewBanner /> */}

      <main className="max-w-4xl mx-auto px-4 py-12">
        <motion.h1
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-4xl md:text-5xl font-bold text-green-800 text-center mb-8"
        >
          Analyze My Business Idea
        </motion.h1>

        <p className="text-center text-gray-600 mb-12 max-w-2xl mx-auto">Get comprehensive analysis to refine your business concept.</p>

        <div className="bg-white rounded-2xl shadow-xl p-8 mb-12">
          {message && (
            <div
              className={`mb-6 p-4 rounded-lg ${
                showRechargeLink ? "bg-yellow-50 border-l-4 border-yellow-400" : "bg-red-50 border-l-4 border-red-400"
              }`}
            >
              <p className="text-sm font-medium text-gray-800">{message}</p>
              {showRechargeLink && (
                <Link to="/credits/" className="inline-block mt-2 text-green-600 hover:text-green-800 font-semibold">
                  Recharge credits →
                </Link>
              )}
            </div>
          )}

          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label htmlFor="businessIdea" className="block text-sm font-medium text-gray-700 mb-2">
                Describe your business idea
              </label>
              <textarea
                id="businessIdea"
                value={businessIdea}
                onChange={(e) => setBusinessIdea(e.target.value)}
                placeholder="Detail your business concept, including unique features, target market, pricing strategy, and potential challenges. The more comprehensive your description, the more tailored our analysis will be."
                rows="7"
                className="w-full p-4 text-gray-700 bg-gray-50 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent transition-all duration-200"
                required
              />
            </div>

            <div>
              <label htmlFor="language" className="block text-sm font-medium text-gray-700 mb-2">
                Select Report Language
              </label>
              <div className="relative">
                <select
                  id="language"
                  value={language}
                  onChange={(e) => setLanguage(e.target.value)}
                  className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:ring-green-500 focus:border-green-500 sm:text-sm rounded-lg appearance-none bg-white"
                >
                  <option value="english">English</option>
                  <option value="hindi">Hindi</option>
                  <option value="spanish">Spanish</option>
                  <option value="french">French</option>
                  <option value="arabic">Arabic</option>
                  <option value="russian">Russian</option>
                  <option value="portuguese">Portuguese</option>
                  <option value="indonesian">Indonesian</option>
                  <option value="german">German</option>
                  <option value="japanese">Japanese</option>
                  <option value="swahili">Swahili</option>
                  <option value="turkish">Turkish</option>
                  <option value="korean">Korean</option>
                  <option value="italian">Italian</option>
                  <option value="vietnamese">Vietnamese</option>
                  <option value="polish">Polish</option>
                  <option value="ukrainian">Ukrainian</option>
                  <option value="dutch">Dutch</option>
                  <option value="greek">Greek</option>
                  <option value="hebrew">Hebrew</option>
                  <option value="thai">Thai</option>
                  <option value="persian">Persian</option>
                  <option value="czech">Czech</option>
                  <option value="swedish">Swedish</option>
                  <option value="romanian">Romanian</option>
                  <option value="hungarian">Hungarian</option>
                  <option value="danish">Danish</option>
                  <option value="finnish">Finnish</option>
                  <option value="slovak">Slovak</option>
                  <option value="norwegian">Norwegian</option>
                  <option value="serbian">Serbian</option>
                  <option value="bulgarian">Bulgarian</option>
                  <option value="mandarin">Mandarin</option>
                  {/* Add more language options here */}
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <ChevronDown className="h-4 w-4" />
                </div>
              </div>
            </div>

            <button
              type="submit"
              disabled={isSubmitting}
              className={`w-full bg-gradient-to-r from-green-500 to-green-600 text-white text-lg font-semibold py-3 rounded-lg
                ${isSubmitting ? "opacity-75 cursor-not-allowed" : "hover:from-green-600 hover:to-green-700"}
                focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 transition-all duration-300 transform hover:-translate-y-1`}
            >
              {isSubmitting ? (
                <span className="flex items-center justify-center">
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  Generating Your Report...
                </span>
              ) : (
                "Generate My Business Report!"
              )}
            </button>
          </form>
        </div>

        <div className="bg-white rounded-2xl shadow-lg p-8">
          <h2 className="text-2xl font-bold text-gray-800 mb-6">My Previous Full Reports</h2>
          <p className="text-sm text-gray-600 mb-4">
            *Opening a new report deducts report credits automatically. Select the correct report if you have multiple reports.
          </p>
          {isFetchingReports ? (
            <div className="flex justify-center items-center h-24">
              <div className="animate-spin rounded-full h-10 w-10 border-b-2 border-green-500"></div>
            </div>
          ) : (
            <ul className="space-y-4">
              {latestFullReports.length > 0 ? (
                latestFullReports.map((report) => (
                  <li key={report.report_id} className="border-b border-gray-200 pb-4">
                    <Link to={`/full-report/${report.report_id}`} className="text-blue-500 hover:text-green-800 font-medium">
                      {report.input.substring(0, 100) + (report.input.length > 100 ? "..." : "")}
                    </Link>
                    {report.input.length > 100 && (
                      <button onClick={() => openModal(report)} className="ml-4 text-sm text-gray-500 hover:text-gray-700">
                        View More
                      </button>
                    )}
                  </li>
                ))
              ) : (
                <p className="text-gray-600">No previous reports found. Generate your first comprehensive analysis now!</p>
              )}
            </ul>
          )}
        </div>
      </main>
      <ReportFooter />
      <CustomModal isOpen={isModalOpen} onRequestClose={closeModal} content={selectedReport?.input} />

      <Modal
        isOpen={showBoothForm}
        onClose={() => setShowBoothForm(false)}
        className="bg-white rounded-2xl shadow-xl max-w-2xl mx-auto p-6"
      >
        <h3 className="text-2xl font-bold mb-6">Create Your Virtual Booth</h3>

        <div className="space-y-6">
          <div>
            <label className="font-medium">What You Do</label>
            <input type="text" placeholder="One line about your business" className="w-full mt-2 p-3 border rounded-lg" />
          </div>

          <div>
            <label className="font-medium">Looking For</label>
            <select className="w-full mt-2 p-3 border rounded-lg">
              <option>Suppliers</option>
              <option>Distributors</option>
              <option>Partners</option>
              <option>Buyers</option>
            </select>
          </div>

          <div>
            <label className="font-medium">Best Deals Welcome</label>
            <input type="text" placeholder="Deal size you prefer" className="w-full mt-2 p-3 border rounded-lg" />
          </div>
        </div>

        <div className="flex justify-end mt-8">
          <button className="bg-green-600 text-white px-8 py-3 rounded-lg font-medium">Launch My Booth</button>
        </div>
      </Modal>

      <BusinessMatchModal isOpen={showBusinessMatchModal} onClose={() => setShowBusinessMatchModal(false)} />
    </div>
  );
}
