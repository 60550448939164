// import React, { useState, useEffect } from "react";
// import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import axios from "axios";
// import smoothscroll from "smoothscroll-polyfill";

// // Import your components here
// import LandingPage from "./components/LandingPage";
// import SignIn from "./components/SignIn";
// import SignUp from "./components/SignUp";
// import ForgotPassword from "./components/ForgotPassword";
// import Dashboard from "./components/Dashboard";
// import ResetPassword from "./components/ResetPassword";
// import Report from "./components/Report";
// import MiniReport from "./components/MiniReport";
// import FeedbackAssistant from "./components/Feedback";
// import Credits from "./components/Credits";
// import ReportDashboard from "./components/ReportDashboard";
// import Privacy from "./components/Privacy";
// import Terms from "./components/Terms";
// import ReportDemo from "./components/ReportDemo";
// import MiniReportDemo from "./components/MiniReportDemo";
// // import Astrology from "./components/Astrology";
// // import ItDevelopment from "./components/ItDevelopmemt";
// // import BusinessProfile from "./components/BusinessProfile";
// import ReactGA from "react-ga";
// import FirstArticle from "./components/blog/first_article";
// import SecondArticle from "./components/blog/second_article";
// import ThirdArticle from "./components/blog/third_article"; // Import for the third article
// import BlogHub from "./components/BlogHub"; // Import BlogHub
// import RealtimeResearch from "./components/RealtimeResearch";
// import KeywordAlerts from "./components/KeywordAlerts";
// // import PitchConnect from "./components/TBEMainPage";
// import DoorOpeners from "./components/DoorOpeners";

// const App = () => {
// const [isRestricted, setIsRestricted] = useState(false);
// const myIP = "202.142.86.144"; // Replace with your current IP address

// useEffect(() => {
//   // kick off the polyfill!
//   smoothscroll.polyfill();

//   axios
//     .get("https://ipinfo.io?token=54225501c526ff")
//     .then((response) => {
//       const { country, ip } = response.data;
//       // if (["IN", "NG", "PK", "VN"].includes(country) && ip !== myIP) {
//       // if (["IN", "NG", "PK", "VN", "BD", "TZ", "IR", "KP"].includes(country) && ip !== myIP) {
//       if (["NG", "PK", "VN", "BD", "TZ", "IR", "KP"].includes(country) && ip !== myIP) {
//         // if (["NG", "PK"].includes(country) && ip !== myIP) {
//         setIsRestricted(true);
//       }
//     })
//     .catch((error) => {
//       console.error("Error fetching user location:", error);
//     });

//   // Track page view on component mount
//   ReactGA.pageview(window.location.pathname + window.location.search);
// }, []);

// if (isRestricted) {
//   return (
//     <div>
//       Unfortunately, this service/website is not available in your country.
//       <br />
//       {/* If you think this is an error please contact business@feedbackbyai.com{" "} */}
//     </div>
//   );
// }

//   return (
//     <Router>
//       <Routes>
//         <Route path="" element={<LandingPage />} />
//         <Route path="/signin/" element={<SignIn />} />
//         <Route path="/signup/" element={<SignUp />} />
//         <Route path="/forgot-password/" element={<ForgotPassword />} />
//         <Route path="/reset-password/:uidb64/:token" element={<ResetPassword />} />
//         <Route path="/full-report/:reportId" element={<Report />} />
//         <Route path="/mini-report/:reportId" element={<MiniReport />} />
//         <Route path="/dashboard/" element={<ReportDashboard />} />
//         <Route path="/previewx/" element={<ReportDemo />} />
//         <Route path="/minipreview/" element={<MiniReportDemo />} />
//         <Route path="/feedback" element={<FeedbackAssistant />} />
//         <Route path="/credits/" element={<Credits />} />
//         <Route path="/report/" element={<ReportDashboard />} />
//         <Route path="/privacy/" element={<Privacy />} />
//         <Route path="/terms/" element={<Terms />} />
//         <Route path="/dooropeners/" element={<DoorOpeners />} />
//         {/* <Route path="/dooropeners/" element={<KeywordAlerts />} /> */}
//         {/* <Route path="/realtimeresearch/" element={<RealtimeResearch />} /> */}
//         {/* <Route path="/connect/" element={<PitchConnect />} /> */}
//         {/* <Route path="/feedback-by-astrology/" element={<Astrology />} />
//         <Route path="/it-development/" element={<ItDevelopment />} />
//         <Route path="/profile/" element={<BusinessProfile />} />

//         {/* <Route path="/blog/how-to-validate-your-business-idea-10-best-frameworks/" element={<FirstArticle />} /> */}
//         <Route path="/blog/" element={<BlogHub />} />
//         <Route path="/blog/ai-validate-business-idea-in-seconds/" element={<FirstArticle />} />
//         <Route path="/blog/discovering-customer-needs-ai/" element={<SecondArticle />} />
//         <Route path="/blog/getting-unbiased-feedback-on-your-business-idea-from-ai-characters/" element={<ThirdArticle />} />
//         {/* Add other routes here */}
//       </Routes>
//     </Router>
//   );
// };

// export default App;

import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import axios from "axios";
import smoothscroll from "smoothscroll-polyfill";
import ReactGA from "react-ga";
// import api from "./axiosConfig";
import api from "./components/axiosConfig";
// Import your components here
import LandingPage from "./components/LandingPage";
import SignIn from "./components/SignIn";
import SignUp from "./components/SignUp";
import ForgotPassword from "./components/ForgotPassword";
import Dashboard from "./components/Dashboard";
import ResetPassword from "./components/ResetPassword";
import Report from "./components/Report";
import MiniReport from "./components/MiniReport";
import FeedbackAssistant from "./components/Feedback";
import Credits from "./components/Credits";
import ReportDashboard from "./components/ReportDashboard";
import Privacy from "./components/Privacy";
import Terms from "./components/Terms";
import ReportDemo from "./components/ReportDemo";
import MiniReportDemo from "./components/MiniReportDemo";
import FirstArticle from "./components/blog/first_article";
import SecondArticle from "./components/blog/second_article";
import ThirdArticle from "./components/blog/third_article";
import BlogHub from "./components/BlogHub";
import DoorOpeners from "./components/DoorOpeners";
import FeedbackAISetupAndMonitor from "./components/KeywordAlerts";
import Minuutes from "./components/Minuutes";

import CreateTestForm from "./components/Betterby100CreateTest";
import BetterBy100Marketplace from "./components/Betterby100Marketplace";
import EditTest from "./components/Betterby100EditTest";

import BetterBy100ResultDashboard from "./components/Betterby100ResultDashboard";
import SpotlighteCreate from "./components/SpotlighteCreate";
import SpotlighteList from "./components/SpotlighteList";
// import AIMarketGapFinder from "./components/AIMarketGapFinder";

const App = () => {
  const [isRestricted, setIsRestricted] = useState(false);
  const [clientIP, setClientIP] = useState(null);
  const ipInfoToken = "54225501c526ff"; // Replace with your ipinfo.io token
  const myIP = "152.58.191.51"; // Replace with your actual IP address
  // const myIP = "202.142.76.789"; // Replace with your actual IP address

  // useEffect(() => {
  //   // Kick off smooth scroll polyfill
  //   smoothscroll.polyfill();

  //   // Fetch client IP using ipinfo.io
  //   axios
  //     .get(`https://ipinfo.io/json?token=${ipInfoToken}`)
  //     .then((response) => {
  //       const ip = response.data.ip;
  //       setClientIP(ip);
  //       // console.log("Fetched IP from ipinfo.io:", ip);

  //       // Skip restriction check if IP matches your IP
  //       if (ip === myIP) {
  //         // console.log("IP matches myIP, skipping VPN/proxy check.");
  //         return;
  //       }

  //       // Send IP to backend for VPN/Proxy check
  //       // console.log("Sending IP to backend for VPN/proxy check:", ip);
  //       return api.post("api/check-vpn-proxy/", { ip });
  //     })
  //     .then((response) => {
  //       if (response) {
  //         // Ensure response is valid (skipped if IP matches myIP)
  //         const { is_vpn, country } = response.data;
  //         // console.log("Received VPN/proxy check response:", { is_vpn, country });

  //         // const restrictedCountries = ["NG", "PK", "VN", "BD", "TZ", "IR", "KP", "IN"];
  //         const restrictedCountries = ["NG", "PK", "VN", "BD", "TZ", "IR", "KP"];

  //         // Set restriction status based on VPN/proxy status or restricted country
  //         if (is_vpn || restrictedCountries.includes(country)) {
  //           setIsRestricted(true);
  //           console.log("Access restricted based on VPN/proxy or country.");
  //         } else {
  //           console.log("No restrictions for this IP.");
  //         }
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching IP information or checking VPN:", error);
  //     });

  //   // Track page view on component mount
  //   ReactGA.pageview(window.location.pathname + window.location.search);
  // }, []);

  // if (isRestricted) {
  //   return <div>Unfortunately, this service/website is not available in your country.</div>;
  // }

  // const [isRestricted, setIsRestricted] = useState(false);
  // const myIP = "202.142.86.144"; // Replace with your current IP address

  useEffect(() => {
    // kick off the polyfill!
    smoothscroll.polyfill();

    axios
      .get("https://ipinfo.io?token=54225501c526ff")
      .then((response) => {
        const { country, ip } = response.data;
        // if (["IN", "NG", "PK", "VN"].includes(country) && ip !== myIP) {
        // if (["IN", "NG", "PK", "VN", "BD", "TZ", "IR", "KP"].includes(country) && ip !== myIP) {
        // if (["IN", "NG", "PK", "VN", "BD", "TZ", "IR", "KP"].includes(country) && ip !== myIP) {
        if (["NG", "PK", "VN", "BD", "TZ", "IR", "KP"].includes(country) && ip !== myIP) {
          // if (["NG", "PK"].includes(country) && ip !== myIP) {
          setIsRestricted(true);
        }
      })
      .catch((error) => {
        console.error("Error fetching user location:", error);
      });

    // Track page view on component mount
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  if (isRestricted) {
    return (
      <div>
        Unfortunately, this service/website is not available in your country.
        <br />
        {/* If you think this is an error please contact business@feedbackbyai.com{" "} */}
      </div>
    );
  }

  return (
    <Router>
      <Routes>
        <Route path="" element={<LandingPage />} />
        <Route path="/signin/" element={<SignIn />} />
        <Route path="/signup/" element={<SignUp />} />
        <Route path="/forgot-password/" element={<ForgotPassword />} />
        <Route path="/reset-password/:uidb64/:token" element={<ResetPassword />} />
        <Route path="/full-report/:reportId" element={<Report />} />
        <Route path="/mini-report/:reportId" element={<MiniReport />} />
        <Route path="/dashboard/" element={<ReportDashboard />} />
        <Route path="/previewx/" element={<ReportDemo />} />
        <Route path="/minipreview/" element={<MiniReportDemo />} />
        <Route path="/feedback" element={<FeedbackAssistant />} />
        <Route path="/credits/" element={<Credits />} />
        <Route path="/report/" element={<ReportDashboard />} />
        <Route path="/privacy/" element={<Privacy />} />
        <Route path="/terms/" element={<Terms />} />
        {/* <Route path="/dooropeners/" element={<DoorOpeners />} /> */}
        {/* <Route path="/minuutes/" element={<Minuutes />} /> */}
        <Route path="/spotlighte/create/" element={<SpotlighteCreate />} />
        <Route path="/spotlighte/" element={<SpotlighteList />} />
        {/* <Route path="/aimarketgapfinder/" element={<AIMarketGapFinder />} /> */}

        {/* BetterBy100 Marketplace */}

        <Route path="/better/" element={<BetterBy100Marketplace />} />

        <Route path="/better/create" element={<CreateTestForm />} />
        <Route path="/result/:landingPageId" element={<BetterBy100ResultDashboard />} />

        <Route path="/edit/:testId" element={<EditTest />} />

        {/* <Route path="/edit" element={<EditablePage />} /> */}
        {/* <Route path="/audience-finder/" element={<FeedbackAISetupAndMonitor />} /> */}
        <Route path="/blog/" element={<BlogHub />} />
        <Route path="/blog/ai-validate-business-idea-in-seconds/" element={<FirstArticle />} />
        <Route path="/blog/discovering-customer-needs-ai/" element={<SecondArticle />} />
        <Route path="/blog/getting-unbiased-feedback-on-your-business-idea-from-ai-characters/" element={<ThirdArticle />} />
      </Routes>
    </Router>
  );
};

export default App;
