import React, { useState, useEffect } from "react";
import { Star, Clock, AlertCircle, Sparkles, Upload, Target, Gift, Plus } from "lucide-react";
import axios from "axios";

export default function SpotlighteCreate() {
  const [formData, setFormData] = useState({
    name: "",
    pitch: "",
    description: "",
    category: "",
    location: "",
    founder: {
      name: "",
      role: "",
      bio: "",
      photo: null,
    },
    coverImage: null,
    traction: [""],
    looking: [{ type: "", amount: "" }],
    offering: [""],
    // duration: 1,
    duration: 7,
  });
  const [userData, setUserData] = useState({ email: "", id: "" });
  const [environment, setEnvironment] = useState("local");
  const [step, setStep] = useState("form"); // "form", "payment", "success"
  const [previewMode, setPreviewMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [spotlightId, setSpotlightId] = useState(null);

  const lemonSqueezyUrls = {
    local: {
      1: "https://feedbackbyai1.lemonsqueezy.com/buy/b04375e6-03c6-41f0-b38a-12db842ac8df",
      2: "https://feedbackbyai1.lemonsqueezy.com/buy/77eb5d12-68d9-4522-8c4e-5fe09cc6be25",
      3: "https://feedbackbyai1.lemonsqueezy.com/buy/ac622e09-a54b-464a-af37-049a02d78fe8",
      5: "https://feedbackbyai1.lemonsqueezy.com/buy/b0a4ed91-1a58-4e95-9703-b20ea303e628",
    },
    production: {
      1: "https://feedbackbyai1.lemonsqueezy.com/buy/94e27f93-2a8b-442f-b12e-0ac946b34736",
      2: "https://feedbackbyai1.lemonsqueezy.com/buy/95a249ed-5f15-4aa8-957c-6fda68b8b870",
      3: "https://feedbackbyai1.lemonsqueezy.com/buy/d446dc95-1c70-4e2b-adaf-85ed01a780c4",
      5: "https://feedbackbyai1.lemonsqueezy.com/buy/380e8c03-7d75-4fb3-a34b-31f36ab8d2dd",
    },
  };

  useEffect(() => {
    // Fetch user data
    const fetchUserData = async () => {
      const token = localStorage.getItem("access");
      if (!token) {
        alert("Please log in first!");
        return;
      }
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}get-user-email/`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const { email, id } = response.data;
        setUserData({ email, id });
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, []);

  const handleImageUpload = (e, type) => {
    const file = e.target.files[0];
    if (file) {
      const previewUrl = URL.createObjectURL(file);
      if (type === "cover") {
        setFormData({ ...formData, coverImage: { file, previewUrl } });
      } else {
        setFormData({
          ...formData,
          founder: { ...formData.founder, photo: { file, previewUrl } },
        });
      }
    }
  };

  const addField = (field) => {
    if (field === "looking") {
      setFormData({
        ...formData,
        looking: [...formData.looking, { type: "", amount: "" }],
      });
    } else if (field === "offering") {
      setFormData({
        ...formData,
        offering: [...formData.offering, ""],
      });
    } else if (field === "traction") {
      setFormData({
        ...formData,
        traction: [...formData.traction, ""],
      });
    }
  };

  const updateField = (field, value, index = null, subfield = null) => {
    if (index !== null) {
      if (field === "looking") {
        const newLooking = [...formData.looking];
        newLooking[index] = {
          ...newLooking[index],
          [subfield]: value,
        };
        setFormData({ ...formData, looking: newLooking });
      } else {
        const newArray = [...formData[field]];
        newArray[index] = value;
        setFormData({ ...formData, [field]: newArray });
      }
    } else if (field === "founder") {
      setFormData({
        ...formData,
        founder: { ...formData.founder, [subfield]: value },
      });
    } else {
      setFormData({ ...formData, [field]: value });
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    setError("");
    const token = localStorage.getItem("access");
    if (!token) {
      alert("Please log in first!");
      setLoading(false);
      return;
    }

    const form = new FormData();
    form.append(
      "data",
      JSON.stringify({
        name: formData.name,
        pitch: formData.pitch,
        description: formData.description,
        category: formData.category,
        location: formData.location,
        founder: {
          name: formData.founder.name,
          role: formData.founder.role,
          bio: formData.founder.bio,
        },
        traction: formData.traction,
        looking: formData.looking,
        offering: formData.offering,
        // duration: formData.duration,
        duration: 7,
      })
    );
    // if (formData.coverImage) form.append("cover_image", formData.coverImage);
    // if (formData.founder.photo) form.append("founder_photo", formData.founder.photo);
    if (formData.coverImage?.file) form.append("cover_image", formData.coverImage.file);
    if (formData.founder.photo?.file) form.append("founder_photo", formData.founder.photo.file);

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}spotlight/create/`, form, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });

      const createdSpotlightId = response.data.spotlight_id; // Extract spotlight_id
      if (createdSpotlightId) {
        setSpotlightId(createdSpotlightId); // Save spotlight_id in state
        alert("Spotlight created successfully!");
        // setStep("payment");
        setStep("success");
      } else {
        throw new Error("Failed to retrieve spotlight ID.");
      }
    } catch (error) {
      console.error("Error creating spotlight:", error);
      setError("Failed to create spotlight. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handlePayment = () => {
    if (!spotlightId) {
      alert("Spotlight ID is not available. Please create the spotlight first.");
      return;
    }

    const paymentUrl = lemonSqueezyUrls[environment][formData.duration].concat(
      `?checkout[email]=${
        userData.email
      }&checkout[custom][report_credits]=0&checkout[custom][question_credits]=0&checkout[custom][mini_report]=0&checkout[custom][type]=spotlighte&checkout[custom][astroplan]=no&checkout[custom][astrologyReportId]=0&checkout[custom][spotlight_days]=${
        formData.duration * 7
      }&checkout[custom][userId]=${userData.id}&checkout[custom][spotlightId]=${spotlightId}` // Use spotlightId from state
    );

    window.location.href = paymentUrl; // Redirect to payment URL
  };

  // const handlePayment = () => {
  //   const paymentUrl = lemonSqueezyUrls[environment][formData.duration].concat(
  //     // `?checkout[email]=${userData.email}&checkout[custom][userId]=${userData.id}&checkout[custom][spotlight_days]=${formData.duration * 7}`
  //     `?checkout[email]=${
  //       userData.email
  //     }&checkout[custom][report_credits]=0&checkout[custom][question_credits]=0&checkout[custom][mini_report]=0&checkout[custom][type]=spotlighte&checkout[custom][astroplan]=no&checkout[custom][astrologyReportId]=0&checkout[custom][spotlight_days]=${
  //       formData.duration * 7
  //     }&checkout[custom][userId]=${userData.id}`
  //   );
  //   window.location.href = paymentUrl;
  // };

  const PreviewCard = () => (
    <div className="bg-white rounded-xl shadow-lg overflow-hidden border">
      <div className="relative h-48">
        {formData.coverImage ? (
          <img src={formData.coverImage?.previewUrl} alt="Cover" className="w-full h-full object-cover" />
        ) : (
          <div className="absolute inset-0 bg-gray-100 flex items-center justify-center">
            <Upload className="w-8 h-8 text-gray-400" />
          </div>
        )}
        <div className="absolute inset-0 bg-gradient-to-t from-black/60 via-black/30 to-transparent">
          <div className="absolute bottom-6 left-6 text-white">
            <div className="flex items-center space-x-3 mb-3">
              <span className="bg-amber-500 text-white px-3 py-1 rounded-full text-sm flex items-center">
                <Star className="w-4 h-4 mr-1" fill="white" />
                Featured
              </span>
              {formData.category && (
                <span className="bg-white/20 backdrop-blur-sm px-3 py-1 rounded-full text-sm">{formData.category}</span>
              )}
              {formData.location && <span className="bg-blue-500 px-3 py-1 rounded-full text-sm">{formData.location}</span>}
            </div>
            <h2 className="text-2xl font-bold mb-1">{formData.name || "Your Startup Name"}</h2>
            <p className="text-lg opacity-90">{formData.pitch || "Your One-line Pitch"}</p>
          </div>
        </div>
      </div>

      <div className="p-6 space-y-6">
        {/* Founder Info */}
        <div className="flex items-center space-x-4">
          <div className="w-12 h-12 bg-gray-100 rounded-full overflow-hidden">
            {formData.founder.photo ? (
              <img src={formData.founder.photo?.previewUrl} alt={formData.founder.name} className="w-full h-full object-cover" />
            ) : (
              <div className="w-full h-full flex items-center justify-center">
                <Upload className="w-4 h-4 text-gray-400" />
              </div>
            )}
          </div>
          <div>
            <p className="font-bold">{formData.founder.name || "Founder Name"}</p>
            <p className="text-gray-600 text-sm">{formData.founder.role || "Role"}</p>
          </div>
        </div>

        {/* Description */}
        {formData.description && <p className="text-gray-600 line-clamp-3">{formData.description}</p>}

        {/* Looking For & Offering */}
        <div className="grid grid-cols-2 gap-6">
          <div className="bg-blue-50/50 rounded-xl p-6 border border-blue-100">
            <div className="flex items-center text-blue-600 mb-4">
              <Target className="w-5 h-5" />
              <h3 className="font-semibold ml-2">Looking For</h3>
            </div>
            <ul className="space-y-3">
              {formData.looking.map(
                (item, i) =>
                  item.type && (
                    <li key={i} className="flex items-start text-gray-700">
                      <span className="w-2 h-2 bg-blue-600 rounded-full mt-2 mr-2"></span>
                      <span>
                        {item.type} {item.amount && `• ${item.amount}`}
                      </span>
                    </li>
                  )
              )}
            </ul>
          </div>

          <div className="bg-green-50/50 rounded-xl p-6 border border-green-100">
            <div className="flex items-center text-green-600 mb-4">
              <Gift className="w-5 h-5" />
              <h3 className="font-semibold ml-2">We Offer</h3>
            </div>
            <ul className="space-y-3">
              {formData.offering.map(
                (item, i) =>
                  item && (
                    <li key={i} className="flex items-start text-gray-700">
                      <span className="w-2 h-2 bg-green-600 rounded-full mt-2 mr-2"></span>
                      <span>{item}</span>
                    </li>
                  )
              )}
            </ul>
          </div>
        </div>

        {/* Traction */}
        {formData.traction.some((t) => t) && (
          <div className="border-t pt-4">
            <h4 className="font-medium mb-3">Traction & Achievements</h4>
            <ul className="space-y-2">
              {formData.traction.map(
                (item, i) =>
                  item && (
                    <li key={i} className="flex items-center gap-2 text-gray-600">
                      <span className="w-1.5 h-1.5 bg-blue-600 rounded-full"></span>
                      {item}
                    </li>
                  )
              )}
            </ul>
          </div>
        )}
      </div>
    </div>
  );

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-6xl mx-auto px-4 py-12">
        {/* Header */}
        <div className="text-center mb-12">
          <div className="inline-flex items-center gap-2 bg-blue-50 text-blue-700 px-4 py-1.5 rounded-full text-sm font-medium mb-4">
            <Sparkles className="w-4 h-4" />
            {/* Launching this Week */}
            {/* Exclusive Early Access */}
            {/* 🚀 22 FOUNDER SPOTS REMAINING */}
            {/* 🌟 FOUNDER EARLY ACCESS (Only 100 Spots) */}
            30 days Free Visibility for First 5 Pitches (Save $199)
            {/* Early Access: $95/week (Regular $195) */}
          </div>
          <h1 className="text-4xl font-bold text-gray-900 mb-3">
            {/* Create Your Spotlight•E Profile */}
            {/* 🚀 Join the Global Small Business Network */}
            {/* 🚀 Join the Future of Business Networking */}
            {/* The Future of Business Networking Starts Here */}
            CREATE NEW PITCH
            {/* Launch Your Startup to 10,000+ Businesses */}
          </h1>
          {/* <p className="text-lg text-gray-600">Showcase your vision to 10k+ businesses on FeedbackByAI</p> */}
          {/* <p className="text-lg text-gray-600">Connect with investors, partners & customers</p> */}
          <p className="text-lg text-gray-600">
            {/* Be among the first to showcase your business on SpotLight•E. Complete your profile now to unlock founder-tier benefits worth
            $499. */}
            {/* Complete all fields to unlock: - Featured to 10,000+ businesses at launch - $499 launch package - free for early founders -
            Priority matching in 24 hours */}
            {/* Only 100 Startups Will Be Featured at Launch - Secure Your Spot Now */}
          </p>
        </div>

        {/* Main Form */}
        <div className="bg-white rounded-2xl shadow-xl p-8">
          <div className="grid lg:grid-cols-2 gap-12">
            {/* Form Section */}
            <div className="space-y-8">
              {/* Basic Info */}
              <section className="space-y-4">
                <h3 className="text-lg font-semibold">Essential Profile</h3>
                <input
                  type="text"
                  placeholder="⭐ Startup Name"
                  value={formData.name}
                  onChange={(e) => updateField("name", e.target.value)}
                  className="w-full p-3 rounded-xl border focus:ring-2 focus:ring-blue-500"
                />
                <input
                  type="text"
                  placeholder="⭐ One-line Pitch "
                  value={formData.pitch}
                  onChange={(e) => updateField("pitch", e.target.value)}
                  className="w-full p-3 rounded-xl border focus:ring-2 focus:ring-blue-500"
                />
                {/* <textarea
                  placeholder="⭐ Story (Used for AI-powered matching)"
                  value={formData.description}
                  onChange={(e) => updateField("description", e.target.value)}
                  rows="4"
                  className="w-full p-3 rounded-xl border focus:ring-2 focus:ring-blue-500"
                /> */}
                <div className="grid grid-cols-2 gap-4">
                  <input
                    type="text"
                    placeholder="⭐ Category"
                    value={formData.category}
                    onChange={(e) => updateField("category", e.target.value)}
                    className="p-3 rounded-xl border focus:ring-2 focus:ring-blue-500"
                  />
                  <input
                    type="text"
                    placeholder="⭐ Location"
                    value={formData.location}
                    onChange={(e) => updateField("location", e.target.value)}
                    className="p-3 rounded-xl border focus:ring-2 focus:ring-blue-500"
                  />
                </div>
              </section>

              {/* Founder Info */}
              <section className="space-y-4">
                <h3 className="text-lg font-semibold">Founder Details:</h3>
                <div className="grid grid-cols-2 gap-4">
                  <input
                    type="text"
                    placeholder="Founder Name"
                    value={formData.founder.name}
                    onChange={(e) => updateField("founder", e.target.value, null, "name")}
                    className="p-3 rounded-xl border focus:ring-2 focus:ring-blue-500"
                  />
                  <input
                    type="text"
                    placeholder="Role/Title"
                    value={formData.founder.role}
                    onChange={(e) => updateField("founder", e.target.value, null, "role")}
                    className="p-3 rounded-xl border focus:ring-2 focus:ring-blue-500"
                  />
                </div>
                {/* <textarea
                  placeholder="Professional Bio"
                  value={formData.founder.bio}
                  onChange={(e) => updateField("founder", e.target.value, null, "bio")}
                  rows="3"
                  className="w-full p-3 rounded-xl border focus:ring-2 focus:ring-blue-500"
                /> */}
                <div className="border-2 border-dashed rounded-xl p-6 text-center">
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleImageUpload(e, "founder")}
                    className="hidden"
                    id="founderPhoto"
                  />
                  <label htmlFor="founderPhoto" className="cursor-pointer">
                    <Upload className="w-6 h-6 mx-auto mb-2 text-gray-400" />
                    <p className="text-sm text-gray-500">Upload Photo (3x higher response rate)</p>
                  </label>
                </div>
              </section>

              {/* Cover Image */}
              <section className="space-y-4">
                <h3 className="text-lg font-semibold">Product Cover Image</h3>
                <div className="border-2 border-dashed rounded-xl p-8 text-center">
                  <input type="file" accept="image/*" onChange={(e) => handleImageUpload(e, "cover")} className="hidden" id="coverImage" />
                  <label htmlFor="coverImage" className="cursor-pointer">
                    <Upload className="w-8 h-8 mx-auto mb-2 text-gray-400" />
                    <p className="text-sm text-gray-500">🎯 Cover Image (16:9) - Featured in our launch campaign</p>
                  </label>
                </div>
              </section>

              {/* Looking For */}
              <section className="space-y-4">
                <h3 className="text-lg font-semibold">What You're Looking For</h3>
                {formData.looking.map((item, index) => (
                  <div key={index} className="grid grid-cols-2 gap-4">
                    <input
                      type="text"
                      placeholder="Type (e.g., Partners)"
                      value={item.type}
                      onChange={(e) => updateField("looking", e.target.value, index, "type")}
                      className="p-3 rounded-xl border focus:ring-2 focus:ring-blue-500"
                    />
                    <input
                      type="text"
                      placeholder="Amount/Details"
                      value={item.amount}
                      onChange={(e) => updateField("looking", e.target.value, index, "amount")}
                      className="p-3 rounded-xl border focus:ring-2 focus:ring-blue-500"
                    />
                  </div>
                ))}
                <button onClick={() => addField("looking")} className="text-blue-600 text-sm font-medium flex items-center gap-1">
                  <Plus className="w-4 h-4" /> Add Another Need
                </button>
              </section>

              {/* Offering */}
              <section className="space-y-4">
                <h3 className="text-lg font-semibold">What You Offer</h3>
                {formData.offering.map((item, index) => (
                  <input
                    key={index}
                    type="text"
                    placeholder="e.g., 'Proprietary Technology'"
                    value={item}
                    onChange={(e) => updateField("offering", e.target.value, index)}
                    className="w-full p-3 rounded-xl border focus:ring-2 focus:ring-blue-500"
                  />
                ))}
                <button onClick={() => addField("offering")} className="text-blue-600 text-sm font-medium flex items-center gap-1">
                  <Plus className="w-4 h-4" /> Add Offering
                </button>
              </section>

              {/* Traction */}
              <section className="space-y-4">
                <h3 className="text-lg font-semibold">Key Achievement</h3>
                {formData.traction.map((item, index) => (
                  <input
                    key={index}
                    type="text"
                    placeholder="e.g., '10K+ Monthly Users'"
                    value={item}
                    onChange={(e) => updateField("traction", e.target.value, index)}
                    className="w-full p-3 rounded-xl border focus:ring-2 focus:ring-blue-500"
                  />
                ))}
                <button onClick={() => addField("traction")} className="text-blue-600 text-sm font-medium flex items-center gap-1">
                  <Plus className="w-4 h-4" /> Add Achievement
                </button>
              </section>

              {/* Duration */}
              {/* <section className="bg-blue-50 rounded-xl p-6 space-y-4">
                <h3 className="font-semibold">Spotlight Duration</h3>
                <div className="grid grid-cols-2 sm:grid-cols-4 gap-3">
                  {[1, 2, 3, 5].map((weeks) => (
                    <button
                      key={weeks}
                      onClick={() => updateField("duration", weeks)}
                      className={`bg-white p-4 rounded-xl border-2 transition-all ${
                        formData.duration === weeks ? "border-blue-500" : "border-transparent hover:border-blue-300"
                      }`}
                    >
                      <div className="font-medium">
                        {weeks} Week{weeks > 1 ? "s" : ""}
                      </div>
                      <div className="text-sm text-gray-600">${95 * weeks}</div>
                    </button>
                  ))}
                </div>
              </section> */}
            </div>

            {/* Preview Panel */}
            <div className="lg:border-l lg:pl-12">
              <div className="sticky top-8 space-y-6">
                <h3 className="text-lg font-semibold">✨ Your Launch Week Feature Preview</h3>
                <PreviewCard />

                <div className="bg-blue-50 rounded-xl p-6">
                  <h4 className="font-medium mb-4">
                    ✨ Launch Benefits:
                    {/* What Happens Next? */}
                  </h4>
                  <ul className="space-y-3">
                    <li className="flex items-start gap-3 text-sm">
                      <Clock className="w-4 h-4 mt-1 text-blue-600" />
                      <span> Priority Review (24h)</span>
                    </li>

                    <li className="flex items-start gap-3 text-sm">
                      <Target className="w-4 h-4 mt-1 text-blue-600" />
                      <span>Featured Placement ($499 Value - Free)</span>
                    </li>
                    <li className="flex items-start gap-3 text-sm">
                      <AlertCircle className="w-4 h-4 mt-1 text-blue-600" />
                      <span>Exposure to 10,000+ Businesses</span>
                    </li>
                    <li className="flex items-start gap-3 text-sm">
                      <Gift className="w-4 h-4 mt-1 text-blue-600" />
                      <span>Premium Analytics Dashboard</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          {/* Submit Button */}
          <div className="mt-12 max-w-xl mx-auto">
            {step === "form" && (
              <button
                onClick={handleSubmit}
                className="w-full bg-gradient-to-r from-blue-600 to-blue-700 hover:from-blue-700 hover:to-blue-800 text-white py-4 rounded-xl font-medium shadow-lg transition-all"
                disabled={loading}
              >
                {/* {loading ? "Submitting..." : `Pay & Submit for Review • $${formData.duration * 95}`} */}
                {loading ? "Submitting..." : `⚡ Submit Profile`}
                {/* {loading ? "Submitting..." : `Submit for Review`} */}
              </button>
            )}
            {/* {step === "payment" && (
              <button
                onClick={handlePayment}
                className="w-full bg-gradient-to-r from-green-600 to-green-700 hover:from-green-700 hover:to-green-800 text-white py-4 rounded-xl font-medium shadow-lg transition-all"
              >
                Proceed to Payment
              </button>
            )} */}
            {step === "success" && (
              <p className="text-center text-green-600 text-lg font-semibold mt-4">
                Your spotlight has been successfully submitted for review!
              </p>
            )}

            <p className="text-center text-sm text-gray-500 mt-4">100% Free Early Access • Setup takes 2 minutes</p>
          </div>
        </div>
      </div>
    </div>
  );
}
